import { useEffect, useRef, useState } from 'react'

const useInViewport = <T extends HTMLElement>() => {
  const r = useRef<T>(null)
  const [isInViewport, setIsInViewport] = useState(false)

  useEffect(() => {
    if (!r.current) {
      return
    }
    const observer = new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting))
    observer.observe(r.current as T)
    return () => observer.disconnect()
  }, [r])

  return { isInViewport, r }
}

export default useInViewport
