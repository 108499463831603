import { ErrorBoundary } from '@sentry/react'
import classNames from 'classnames'
import { FC } from 'react'

import useSectionView from '../../hooks/clevertap/useSectionView'
import { TopicStub } from '../../interfaces'
import TopicItem from '../TopicItem'
import styles from './TopicSection.module.css'
import ViewAll from './ViewAll'

interface Props {
  ageType: string
  topics: Array<TopicStub>
  className?: string
  title: string
  subTitle?: string
}

const visibleTopics = 5
const minTopicsOnLine = 3

const TopicSection: FC<Props> = props => {
  const topics = props.topics
    ?.slice(0, visibleTopics)
    ?.map((topic, index) => (
      <TopicItem index={index} key={topic.topicId} linkUrl={`/categories/${topic.topicId}?ageType=${props.ageType}`} topic={topic} widthClass={styles.topicImage} />
    ))

  topics?.push(<ViewAll key='viewall' productCount={props.topics.length} topicImageClass={styles.topicImage} />)

  const lines = [] as Array<JSX.Element>
  for (let i = 0; i < topics.length; i += minTopicsOnLine) {
    const line = topics.slice(i, i + minTopicsOnLine)
    lines.push(
      <div className={styles.topicsLine} key={i}>
        {line}
      </div>
    )
  }
  const { r } = useSectionView<HTMLElement>({ section: 'topics' })

  return (
    <ErrorBoundary fallback={<></>}>
      <section className={styles.wrapper} ref={r}>
        <header className={classNames(styles.header, props.className)}>
          <h4>{props.title}</h4>
          {props.subTitle && <h3>{props.subTitle}</h3>}
        </header>
        <div className={styles.topics}>{lines}</div>
      </section>
    </ErrorBoundary>
  )
}

export default TopicSection
