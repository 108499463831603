import React, { FC } from 'react'

import useSectionView from '../../hooks/clevertap/useSectionView'
import Slide from '../Slide'
import Background from './Background'
import { slideSettings } from './constants'
import styles from './Showcase.module.css'
import Title from './Title'

type Props = {
  styles?: {
    backgroundTitle?: string
    backgroundRectangle?: string
  }
  schema: schema
  section: string
  cards: Array<JSX.Element>
}

type schema = {
  actionUrl: string
  subTitle: string
  title: string
}

const Showcase: FC<Props> = props => {

  const { r } = useSectionView<HTMLDivElement>({ section: props.section })

  if (!props.cards.length) {
    return null
  }
  return (
    <div className={styles.wrapper} ref={r}>
      <Background styles={props.styles} />
      <div className={styles.showcaseWrapper}>
        <div className={styles.showcaseWrapperContent}>
          <Title {...props} />
          {props.cards && <Slide cards={props.cards} {...slideSettings} />}
        </div>
      </div>
    </div>
  )
}

export default Showcase
