import classNames from 'classnames'
import React, { FC } from 'react'

import styles from './Background.module.css'

type Props = {
  styles?: {
    backgroundRectangle?: string
  }
}

const Background: FC<Props> = props => <div className={classNames(styles.backgroundRectangle, props.styles?.backgroundRectangle)}></div>

export default Background
