import { Trans } from '@lingui/macro'
import { ErrorBoundary } from '@sentry/react'
import Link from 'next/link'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import GooglePlayButton from '../buttons/GooglePlayButton'
import Highlight from '../Highlight'
import styles from './GooglePlaySection.module.css'

const section = 'googleplay'

const GooglePlaySection = () => (
  <ErrorBoundary fallback={<></>}>
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <Link href={process.env.NEXT_PUBLIC_PLAYSTORE_URL as string}>
          <a className={styles.link} onClick={() => pushCleverEvent('click', { keyAction: 'gPlay1', section })}>
            <em>
              <strong>
                <Trans>
                  India&apos;s Largest Pregnancy &amp; Parenting Platform, <Highlight>trusted by 1 Crore+ women.</Highlight>
                </Trans>
              </strong>
            </em>
          </a>
        </Link>
      </div>
      <div className={styles.icon}>
        <GooglePlayButton section={section} />
      </div>
    </div>
  </ErrorBoundary>
)

export default GooglePlaySection
