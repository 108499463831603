export const homeLdJson = {
  '@context': 'http://www.schema.org',
  '@type': 'ProfessionalService',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'India',
    addressLocality: 'HSR Layout, Bengaluru',
    addressRegion: 'Karnataka',
    postalCode: '560102',
    streetAddress: ' Healofy, #140, 2nd Floor, above Jana Bank, Sector 6',
    telephone: '+91-8041253795',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'Customer Service',
    telephone: '+91-8041253795',
  },
  description: '',
  image: 'https://p.healofy.net/web/header.webp',
  name: 'Indian Pregnancy, Parenting &amp; Baby Products Shopping platform',
  url: 'https://content.healofy.com/',
}
