import { FeedQAResponsePage } from '../../api/feed'
import { FeedObjectQuestionView } from '../../interfaces'
import { CustomisedFeedObjectQuestion } from '.'

export const normailzeData = (data: FeedQAResponsePage) => {
  const questionBlogData = (data?.payload?.feedViewWebResponse?.feedView?.feedObjectViews ?? []) as Array<FeedObjectQuestionView>
  const userList = data?.payload?.feedViewWebResponse?.feedView?.userViews ?? []
  return questionBlogData.map((item: FeedObjectQuestionView) => {
    let question = (item?.question ?? {}) as CustomisedFeedObjectQuestion
    const answerList = question.answerList || []
    if (answerList.length > 0) {
      const answer = answerList[0]
      const userId = answer?.userId ?? ''
      const idx = userList.findIndex(user => user.userId === userId)
      if (idx != -1) question = { ...question, blogAnswer: answer?.answerText?.value ?? '', userData: userList[idx] }
    }
    return question
  })
}
