import classNames from 'classnames'
import { FC, MouseEventHandler } from 'react'

import { BackwardArrow } from '../icons'
import styles from './Slide.module.css'

interface Props {
  className?: string
  onClick?: MouseEventHandler
  customClass?: string
}

const PrevArrow: FC<Props> = props => {
  return (
    <div className={classNames(styles.showcasePrevButtonWrapper, props.customClass)}>
      <button style={{ background: 'white' }} className={props.className} onClick={props.onClick}>
        <BackwardArrow color='#ff3278' />
      </button>
    </div>
  )
}

export default PrevArrow
