import { Review } from '../interfaces'

const reviews = [
  {
    name: 'Hansika Gandhi',
    profileImageUrl: 'https://p.healofy.net/web/profiles/hansika-gandhi.jpeg',
    rating: 5,
    text: 'Healofy app has been very helpful ever since I have conceived my baby.It helped me answer all my queries regarding pregnancy and it became a true guide for me. I m still using for parenting tips. And now, I m an influencer on it because I love to help others. I suggested the Healofy app to my other pregnant friends and all have fallen in love with it.',
  },
  {
    name: 'Sneha Goinka',
    profileImageUrl: 'https://p.healofy.net/web/profiles/sneha-goinka.jpeg',
    rating: 4.5,
    text: "This app is very useful for me, allots of solutions regarding kids like cold,cough ,fever, motions and constipation .Also give solutions for fussy eater. Charts according to your child's age. Now new features of shopping and to earn money sitting at home. New update is also started now every women can search products and sell and earn money.:+1:",
  },
  {
    name: 'Jeba Praveen',
    profileImageUrl: 'https://p.healofy.net/web/profiles/jeba-praveen.jpeg',
    rating: 5,
    text: "Amazing and informative app.. Healofy resolves all kinds of pregnancy and parenting queries which a woman can have especially when it's her first child. This app has been a real guide to me throughout my journey so far from pregnancy to parenting. I am really thankful to Healofy.",
  },
  {
    name: 'Shruti Nigam',
    profileImageUrl: 'https://p.healofy.net/web/profiles/shruti-nigam.jpeg',
    rating: 5,
    text: "I am in love with the healofy app. it helped me a lot with every each of the detail required at every point. it suggested to me what is best for me and my baby, mostly in this corona timehealofy saved me from regular visits to the hospital's. I never had the need or felt the desired to look from for any other apps Google for support.",
  },
  {
    name: 'Sharmila Aggarwal',
    profileImageUrl: 'https://p.healofy.net/web/profiles/sharmila-aggarwal.jpeg',
    rating: 4.5,
    text: 'Healofy app is a treasure of all kinds of knowledge a woman needs to know in their pregnancy or parenting stage. For me Healofy acted as a genie which was available whenever I needed any information about my baby or myself. It also has a range of quality products for kids and new moms which made my life so much easier. Big thanks to Healofy!',
  },
] as Array<Review>

export const retrieveReviews = () => reviews
