import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { FC } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import styles from './ViewAll.module.css'

interface Props {
  productCount: number
  topicImageClass: string
}

const ViewAll: FC<Props> = props => {
  return (
    <Link href='/categories'>
      <a className={styles.topic} onClick={() => pushCleverEvent('click', { keyAction: 'viewall', section: 'topics' })}>
        <svg version='1.1' viewBox='0 0 207 206' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
          <title>Bitmap Copy 2</title>
          <defs>
            <rect id='path-1' x='0' y='0' width='100%' height='100%' rx='8.09889925' />
            <filter x='-2.2%' y='-2.2%' width='104.5%' height='104.5%' filterUnits='objectBoundingBox' id='filter-3'>
              <feOffset dx='0' dy='0' in='SourceAlpha' result='shadowOffsetOuter1' />
              <feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
              <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1' />
            </filter>
          </defs>
          <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Bitmap-Copy-2' transform='translate(3.397760, 3.000000)'>
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#path-1' />
              </mask>
              <g id='Mask'>
                <use fill='black' fillOpacity='1' filter='url(#filter-3)' xlinkHref='#path-1' />
                <use fill='#FF3278' fillRule='evenodd' xlinkHref='#path-1' />
              </g>
              <g id='Group-72' mask='url(#mask-2)'>
                <g transform='translate(75.000000, 75.000000)' id='Group-66-Copy'>
                  <circle id='Oval' fill='#FFFFFF' cx='25' cy='25' r='25' />
                  <polyline
                    id='Rectangle'
                    stroke='#FF3278'
                    strokeWidth='5.00365497'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    transform='translate(20.606602, 25.606602) rotate(-45.000000) translate(-20.606602, -25.606602) '
                    points='28.1066017 18.1066017 28.1066017 33.1066017 13.1066017 33.1066017'
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span className={styles.topicText}>
          <Trans>VIEW ALL {props.productCount}</Trans>
        </span>
      </a>
    </Link>
  )
}

export default ViewAll
