import { Trans, t } from '@lingui/macro'
import { ErrorBoundary } from '@sentry/react'
import Link from 'next/link'
import React, { FC } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import { BlogItem } from '../../interfaces'
import { ForwardArrow } from '../icons'
import Showcase from '../Showcase'
import styles from './NewBlog.module.css'

const section = 'blogs'
interface Props {
  data: Array<BlogItem>
}

const NewBlog: FC<Props> = props => {
  const schema = {
    actionUrl: '/blogs',
    subTitle: t`chosen for your specific needs`,
    title: t`TOP BLOGS BY DOCTOR`,
  }

  const cards =
    props.data?.length > 2 &&
    props.data?.map((item, index) => {
      const relativeLink = `/blogs/${item.url}`
      return (
        <ErrorBoundary fallback={<></>} key={item.id}>
          <div className={styles.articleWrapper}>
            <Link href={relativeLink}>
              <a className={styles.link} onClick={() => pushCleverEvent('click', { blogId: item.id, index, keyAction: 'read', section })}>
                <img className={styles.articleImage} src={item.imageUrl} alt='' />
                <div>
                  <div className={styles.articleTitle}>{item.title}</div>
                  <div className={styles.articleReadButtonWrapper}>
                    <span className={styles.articleReadButton}>
                      <span className={styles.read}>
                        <Trans>READ</Trans>
                      </span>
                      <ForwardArrow className={styles.forwardArrowIcon} />
                    </span>
                  </div>
                </div>
              </a>
            </Link>
          </div>
        </ErrorBoundary>
      )
    })
  return <Showcase cards={cards || []} schema={schema} section={section} styles={styles} />
}

export default NewBlog
