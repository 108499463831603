import { contextNames } from '../data/errors'
import { PageMetadata } from '../interfaces'
import { request } from './request'

const seoAPI = process.env.NEXT_PUBLIC_API_SEO as string

export const retrieveSeoData = async (uri: string) => {
  try {
    const metadata = await request<PageMetadata>(
      'get',
      `${seoAPI}/pages/${encodeURIComponent(uri)}`,
      undefined,
      undefined,
      contextNames.seo
    )
    return metadata || {}
  } catch (error) {
    return {}
  }
}
