import { FC } from 'react'
import Rating from 'react-rating'

import { Review } from '../../interfaces'
import Img from '../Img'
import styles from './ReviewCard.module.css'

interface Props {
  review: Review
}

const ReviewCard: FC<Props> = props => (
  <div className={styles.card}>
    <Img className={styles.profilePic} height={50} src={props.review.profileImageUrl} width={50} />
    <Rating
      emptySymbol={<div className={styles.emptyStar}>★</div>}
      fullSymbol={<div className={styles.fullStar}>★</div>}
      initialRating={props.review.rating}
      readonly
    />
    <strong>{props.review.name}</strong>
    <div className={styles.text}>{props.review.text}</div>
  </div>
)

export default ReviewCard
