import { version } from '../configs/version'
import { contextNames } from '../data/errors'
import { Locale } from '../data/intl'
import { ErrorWithData } from '../interfaces'
import * as log from '../utils/log'
import { captureException } from './analytics/sentry'

const createHeaders = (locale?: Locale) => {
  const headers = new Headers()
  headers.set('accept-encoding', 'br, gzip, compress')
  headers.set('content-type', 'application/json')
  headers.set('x-app-version', version)

  if (locale) {
    headers.set('accept-language', locale)
  }

  return headers
}

type Method = 'delete' | 'get' | 'patch' | 'post' | 'put'

interface K8Error {
  error: string
  message: string
}

const parseResponseJSON = async <T>(r: Response) => {
  try {
    const json = await r.json()
    return json as T | K8Error
  } catch (error) {
    return
  }
}

export const request = async <T, K = void>(method: Method, url: string, body?: string, locale?: Locale, ctxName?: string) => {
  const headers = createHeaders(locale)
  const response = await fetch(url, { body, headers, method })

  try {
    const json = await parseResponseJSON(response)
    if (response?.status >= 400) {
      log.error('request failed', response.status, url, json)
      const e = new Error((json as K8Error)?.message || `status ${response?.status}`) as ErrorWithData<K>
      e.data = json as K
      e.url = url
      throw e
    }
    return json as T
  } catch (error) {
    const err = error as ErrorWithData<K>
    err.status = response?.status
    err.url = url
    captureException(err, ctxName || contextNames.genericNetwork, { extra: { body, locale, method, url } })
    throw err
  }
}
