import classNames from 'classnames'
import { FC, MouseEventHandler } from 'react'

import { ForwardArrow } from '../icons'
import styles from './Slide.module.css'

interface Props {
  className?: string
  onClick?: MouseEventHandler
  customClass?: string
}

const NextArrow: FC<Props> = (props) => {
  return (
    <div className={classNames(styles.showcaseNextButtonWrapper, props.customClass)}>
      <button style={{ background: 'white' }} className={props.className} onClick={props.onClick}>
        <ForwardArrow className={styles.forwardArrowIcon} />
      </button>
    </div>
  )
}

export default NextArrow
