import { Trans, t } from '@lingui/macro'
import { ErrorBoundary } from '@sentry/react'
import Link from 'next/link'
import React, { FC } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import { FeedObjectQuestion } from '../../interfaces'
import { toQAUrl } from '../../utils/slug'
import Img from '../Img'
import Showcase from '../Showcase'
import styles from './NewQuestion.module.css'

export type CustomisedFeedObjectQuestion = FeedObjectQuestion & {
  blogAnswer: string
  userData: {
    userName?: string
    profilePicUrl?: string
    fullName?: string
  }
}

const section = 'qa'

type Props = {
  data: Array<CustomisedFeedObjectQuestion>
}

const NewQuestion: FC<Props> = ({ data }) => {
  const schema = {
    actionUrl: '/qa',
    subTitle: t`Asked by similar women`,
    title: t`Question & Answers for you`,
  }

  const cards = data.map((item, index) => {
    const slug = item.slug
    const relativeLink = toQAUrl(slug, item.id)
    return (
      <ErrorBoundary fallback={<></>} key={item.id}>
        <div className={styles.articleOuterWrapper}>
          <div className={styles.articleWrapper}>
            <div className={styles.articleTitle}>{item.questionText.value}</div>
            <div className={styles.articleAnswerCount}>
              <Trans>{item.answerCount} Answers</Trans>
            </div>
            <hr className={styles.articleDivisionLine} />
            {item.userData && (
              <div className={styles.articleAnswerAuthor}>
                <div>
                  <Img
                    className={styles.articleAnswerProfilePic}
                    alt={item.userData.userName}
                    height={26}
                    src={item.userData.profilePicUrl}
                    width={26}
                  />
                </div>
                <div className={styles.articleAnswerAuthorName}>
                  <span>{item.userData.fullName}</span>
                </div>
              </div>
            )}
            <div className={styles.articleAnswerDescription}>{item.blogAnswer}</div>
            <Link href={relativeLink}>
              <a
                className={styles.articleAnswerReadMore}
                onClick={() => pushCleverEvent('click', { index, keyAction: 'readmore', section, questionId: item.id })}
              >
                <Trans>Read More</Trans>
              </a>
            </Link>
          </div>
        </div>
      </ErrorBoundary>
    )
  })

  return <Showcase cards={cards || []} schema={schema} section={section} styles={styles} />
}

export default NewQuestion
