import styles from './Slide.module.css'

export const containerCss = `.container {
  margin-top: 12px;
}
.container :global(.slick-slide) {
  max-width: 400px;
}
.container .slick-arrow {
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #e7e7e7;
  cursor: pointer;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  width: 45px;
}
.container .slick-next::before {
  content: '';
}
.container .slick-prev::before {
  content: '';
}
.container .slick-disabled {
  visibility: hidden;
}
@media only screen and (max-width: 700px) {
  .container :global(.slick-slide) {
    max-width: 250px;
  }
}`

export const dotsCss = `
.${styles.dots} li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 10px;
}

.${styles.dots} li button {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  outline: none;
  padding: 5px;
  width: 20px;
}

.${styles.dots} li button:before {
  color: #ff3278;
  content: '•';
  font-family: 'slick';
  font-size: 6px;
  height: 20px;
  left: 0;
  line-height: 20px;
  opacity: 0.25;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
}

.${styles.dots} li.slick-active {
  color: #ff3278;
}

.${styles.dots} li.slick-active button:before {
  opacity: 0.75;
}`
