import { Trans } from '@lingui/macro'
import { ErrorBoundary } from '@sentry/react'
import { FC } from 'react'

import useSectionView from '../../hooks/clevertap/useSectionView'
import { Review } from '../../interfaces'
import GooglePlayButton from '../buttons/GooglePlayButton'
import ReviewCard from '../ReviewCard'
import Slide from '../Slide'
import styles from './ReviewsSection.module.css'
import { responsive } from './slideResponsive'

const section = 'reviews'

interface Props {
  reviews: Array<Review>
}

const ReviewsSection: FC<Props> = props => {
  const cards = props.reviews.map(review => <ReviewCard review={review} key={review.profileImageUrl} />)

  const { r } = useSectionView<HTMLDivElement>({ section })

  return (
    <ErrorBoundary fallback={<></>}>
      <div className={styles.wrapper} ref={r}>
        <strong>
          <Trans>Reviewed by other similar women</Trans>
        </strong>
        <div className={styles.slideWrapper}>
          <Slide autoplay={true} autoplaySpeed={2000} arrows={false} cards={cards} dots={true} responsive={responsive} slidesToShow={2} />
        </div>
        <div className={styles.footer}>
          <Trans>View All Reviews on Playstore</Trans>
          <div className={styles.playstore}>
            <GooglePlayButton section='reviews' />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default ReviewsSection
