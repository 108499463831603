import { Language, TopicPageDetail, TopicStub } from '../interfaces'
import { request } from './request'

const aggregatorAPI = process.env.NEXT_PUBLIC_API_AGGREGATOR as string

export const retrieveTopics = async (language: Language, feedAge = 'ALL', ageType = 'ALL') => {
  const url = new URL(`${aggregatorAPI}/_ah/api/web/v1/getTopics`)
  if (ageType) {
    url.searchParams.set('ageType', ageType.toUpperCase())
  }
  if (language) {
    url.searchParams.set('language', language)
  }
  if (feedAge) {
    url.searchParams.set('feedAge', feedAge.toUpperCase())
  }
  const { payload } = await request<{ payload?: Array<TopicStub> }>('get', url.toString())
  return payload || []
}

export const retriveTopicPageDetails = (channelId: string, ageType: string, language: Language, page = 0) => {
  const url = new URL(`${aggregatorAPI}/_ah/api/web/v1/getTopicPageDetails`)
  url.searchParams.set('page', page.toString())
  url.searchParams.set('channelId', channelId)
  if (ageType) {
    url.searchParams.set('ageType', ageType.toString())
  }
  url.searchParams.set('language', language.toString())

  return request<TopicPageDetail>('get', url.toString())
}
