import { Trans } from '@lingui/macro'

import useSectionView from '../../hooks/clevertap/useSectionView'
import styles from './SelectStageSection.module.css'
import StageMother from './stages/StageMother'
import StagePFP from './stages/StagePFP'
import StagePregnant from './stages/StagePregnant'

const section = 'selectStage'

const SelectStageSection = () => {
  const { r } = useSectionView<HTMLElement>({ section })
  return (
    <section ref={r}>
      <header className={styles.header}>
        <h4 className={styles.head}>
          <Trans>SELECT YOUR STAGE:</Trans>
        </h4>
        <span>
          <Trans>Get relevant information accordingly</Trans>
        </span>
      </header>
      <div className={styles.stages}>
        <StagePFP section={section} />
        <StagePregnant section={section} />
        <StageMother section={section} />
      </div>
    </section>
  )
}

export default SelectStageSection
