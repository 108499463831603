import { Locale, localeToLanguage } from '../data/intl'
import { Banner, BlogDetails, BlogItem, FeedBlogView, FeedObjectQuestionView, FeedObjectView, FeedUserView, Language } from '../interfaces'
import { request } from './request'

const aggregatorAPI = process.env.NEXT_PUBLIC_API_AGGREGATOR as string

export interface BlogListResponse {
  payload: Array<BlogItem>
  nextPageAvailable?: boolean
}

export interface BlogPageResponse {
  payload: {
    blogs: Array<BlogItem>
    nextPageAvailable: boolean
  }
}

export const retriveBlogList = (feedAge: string, ageType: string, language: Language, page: number, pageType = 'LIFESTAGE') => {
  const url = new URL(`${aggregatorAPI}/_ah/api/web/v1/getBlogs3`)
  url.searchParams.set('feedAge', feedAge)
  url.searchParams.set('ageType', ageType)
  url.searchParams.set('language', language)
  url.searchParams.set('page', page.toString())
  url.searchParams.set('pageType', pageType)
  return request<BlogPageResponse>('get', url.toString())
}

export interface BlogResponse {
  payload?: BlogDetails
}

export const fetchBlog = (slug: string) =>
  request<BlogResponse>('get', `${aggregatorAPI}/_ah/api/internalapis/v1/getBlog?url=${encodeURIComponent(slug)}`)

interface FeedContentDetailsResponse<T extends FeedObjectView> {
  feedObjectViews: Array<T>
  userViews: Array<FeedUserView>
}

export const retrieveContentDetails =
  <T extends FeedObjectView>(contentType: string) =>
  (id: string) => {
    const url = new URL(`${aggregatorAPI}/_ah/api/web/v1/content`)
    url.searchParams.set('contentType', contentType)
    url.searchParams.set('id', id)
    return request<FeedContentDetailsResponse<T>>('get', url.toString())
  }

export const retrieveQuestionDetails = retrieveContentDetails<FeedObjectQuestionView>('QUESTION')

export interface FeedQAResponsePage {
  payload: {
    blogView: FeedBlogView
    feedViewWebResponse: {
      feedView: {
        feedObjectViews: Array<FeedObjectQuestionView>
        pageSize: number
        totalCount: number
        userViews: Array<FeedUserView>
      }
    }
    nextPageAvailable: boolean
  }
}

export const retrieveQAFeed = (locale: Locale, ageType: string, feedAge = 'ALL', pageNumber = 0) => {
  const language = localeToLanguage(locale)
  const url = new URL(`${aggregatorAPI}/_ah/api/web/v1/initialise/v2`)
  url.searchParams.set('feedAge', feedAge)
  url.searchParams.set('ageType', ageType)
  url.searchParams.set('language', language)
  url.searchParams.set('pageNumber', pageNumber.toString())
  url.searchParams.set('feedType', 'QA')
  return request<FeedQAResponsePage>('get', url.toString())
}

export const retrieveBanners = async (locale: Locale) => {
  const language = localeToLanguage(locale)
  const url = new URL(`${aggregatorAPI}/_ah/api/web/v1/homepage/banners`)
  url.searchParams.set('placement', 'WEB')
  url.searchParams.set('language', language)
  url.searchParams.set('ageType', 'ALL')
  const { payload } = await request<{ payload: Array<Banner> }>('get', url.toString())
  return payload
}
