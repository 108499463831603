import { Trans } from '@lingui/macro'
import Link from 'next/link'
import React, { FC } from 'react'

import { pushCleverEvent } from '../../../api/analytics/clevertap'
import { ForwardArrow } from '../../icons'
import styles from './Title.module.css'

interface Props {
  actionUrl: string
  section: string
}

const ShowcaseButton: FC<Props> = props => (
  <div className={styles.backgroundActionButtonWrapper}>
    <Link href={props.actionUrl}>
      <a
        className={styles.backgroundActionButtonTitle}
        onClick={() => pushCleverEvent('click', { keyAction: 'seeall', section: props.section })}
      >
        <Trans>SEE ALL</Trans>
        &ensp;
        <ForwardArrow className={styles.backgroundActionButton} />
      </a>
    </Link>
  </div>
)

export default ShowcaseButton
