import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Head from 'next/head'
import React, { FC } from 'react'
import Slider, { Settings } from 'react-slick'

import { containerCss, dotsCss } from './css'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import styles from './Slide.module.css'

interface Props extends Settings {
  cards: Array<JSX.Element>
  style?: {
    showcaseNextButtonWrapper?: string
    showcasePrevButtonWrapper?: string
  }
}

const Slide: FC<Props> = ({ cards, style, ...rest }) => (
  <>
    <Head>
      <style>
        {containerCss}
        {dotsCss}
      </style>
    </Head>
    <div className='container'>
      <Slider
        dotsClass={styles.dots}
        nextArrow={<NextArrow customClass={style?.showcaseNextButtonWrapper} />}
        prevArrow={<PrevArrow customClass={style?.showcasePrevButtonWrapper} />}
        {...rest}
      >
        {cards.map(card => (
          <div key={card.key}>{card}</div>
        ))}
      </Slider>
    </div>
  </>
)

export default Slide
