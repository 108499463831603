export const slideSettings = {
  arrows: true,
  centerMode: false,
  centerPadding: '60px',
  dots: false,
  infinite: false,
  initialSlide: 0,
  slidesToScroll: 1,
  slidesToShow: 4,
  speed: 500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}
