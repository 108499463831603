import { Trans } from '@lingui/macro'
import { ErrorBoundary } from '@sentry/react'
import classNames from 'classnames'
import Link from 'next/link'

import { pushCleverEvent } from '../../../api/analytics/clevertap'
import * as icons from '../../icons'
import styles from './AgeTypeBar.module.css'

const pfp = 'https://p.healofy.net/web/navigation/ttc.png'
const pregnant = 'https://p.healofy.net/web/navigation/pregnancy.png'
const mother = 'https://p.healofy.net/web/navigation/parenting.png'
const shopPageUrl = process.env.NEXT_PUBLIC_MYSHOP_URL as string

const AgeTypeBar = () => {
  return (
    <ErrorBoundary fallback={<></>}>
      <div className={styles.bar}>
        <Link href='/lifestages/pfp'>
          <a className={styles.option} onClick={() => pushCleverEvent('click', { keyAction: 'pfp' })}>
            <img alt='pfp' className={styles.optionImage} height={36} src={pfp} width={36} />
            <span className={styles.optionText}>
              <Trans>
                Pregnancy <strong>Planning</strong>
              </Trans>
            </span>
          </a>
        </Link>
        <Link href='/lifestages/pregnant'>
          <a className={styles.option} onClick={() => pushCleverEvent('click', { keyAction: 'pregnant' })}>
            <img alt='pregnant' className={styles.optionImage} height={36} src={pregnant} width={36} />
            <span className={styles.optionText}>
              <Trans>
                I am <strong>Pregnant</strong>
              </Trans>
            </span>
          </a>
        </Link>
        <Link href='/lifestages/mother'>
          <a className={styles.option} onClick={() => pushCleverEvent('click', { keyAction: 'mother' })}>
            <img alt='mother' className={styles.optionImage} height={36} src={mother} width={36} />
            <span className={styles.optionText}>
              <Trans>
                I have a <strong>Child</strong>
              </Trans>
            </span>
          </a>
        </Link>
        <Link href={shopPageUrl}>
          <a className={classNames(styles.option, styles.shop)} onClick={() => pushCleverEvent('click', { keyAction: 'mall' })}>
            <span className={styles.optionImage}>
              <icons.Shop />
            </span>
            <span className={styles.shopText}>
              <span className={styles.optionText}>
                <Trans>
                  Your <strong>SHOP</strong>
                </Trans>
              </span>
            </span>
          </a>
        </Link>
      </div>
    </ErrorBoundary>
  )
}

export default AgeTypeBar
