import classNames from 'classnames'
import React from 'react'

import ShowcaseButton from './ShowcaseButton'
import styles from './Title.module.css'

type Props = {
  styles?: {
    backgroundTitle?: string
    backgroundRectangle?: string
    backgroundTitleWrapper?: string
    backgroundWrapper?: string
    autocomplete?: string
  }
  section: string
  schema: schema
}

type schema = {
  actionUrl?: string
  isSearch?: boolean
  placeholder?: string
  subTitle: string
  title: string
}

const Title = (props: Props) => (
  <div className={classNames(styles.backgroundWrapper, props.styles?.backgroundWrapper)}>
    <div className={classNames(styles.backgroundTitleWrapper, props.styles?.backgroundTitleWrapper)}>
      <div className={classNames(styles.backgroundTitle, props.styles?.backgroundTitle)}>{props.schema.title}</div>
      <div className={styles.backgroundSubTitle}>{props.schema.subTitle}</div>
    </div>
    <div className={styles.space}> </div>
    <ShowcaseButton actionUrl={props.schema.actionUrl ?? ''} section={props.section} />
  </div>
)

export default Title
