import { useEffect } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import { ClevertapVisibleStart } from '../../api/analytics/clevertap.events'
import useInViewport from '../useInViewport'

const useSectionView = <T extends HTMLElement>(data: ClevertapVisibleStart) => {
  const { isInViewport, r } = useInViewport<T>()
  useEffect(() => {
    setTimeout(() => {
      if (isInViewport) {
        pushCleverEvent('visible', data)
      } else {
        pushCleverEvent('visible_start', data)
      }
    }, 0)
    // todo - check if this casuses duplicate events
    return () => pushCleverEvent('visible_start', data)
  }, [data, isInViewport])

  return { r }
}

export default useSectionView
