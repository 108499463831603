import classNames from 'classnames'
import Link from 'next/link'
import { FC } from 'react'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import { TopicStub } from '../../interfaces'
import Img from '../Img'
import styles from './TopicItem.module.css'

interface Props {
  index?: number
  linkUrl: string
  topic: TopicStub
  widthClass: string
}

const TopicItem: FC<Props> = props => (
  <Link href={props.linkUrl}>
    <a
      className={classNames(styles.topic, props.widthClass)}
      onClick={() => pushCleverEvent('click', { index: props.index, topic: props.topic.topicId })}
    >
      <Img alt={props.topic.topicId} className={classNames(styles.topicImage)} src={props.topic.url} />
      <span className={styles.topicText}>{props.topic.displayName}</span>
    </a>
  </Link>
)

export default TopicItem
