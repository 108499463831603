import { t } from '@lingui/macro'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'
import useSWR from 'swr'

import { retrieveQAFeed, retriveBlogList } from '../api/feed'
import { retrieveReviews } from '../api/reviews'
import { retrieveSeoData } from '../api/seo'
import { retrieveTopics } from '../api/topics'
import BannersSection from '../components/BannersSection'
import AgeTypeBar from '../components/bars/AgeTypeBar'
import Footer from '../components/Footer'
import GooglePlaySection from '../components/GooglePlaySection'
import Header from '../components/Header'
import MetaTags from '../components/MetaTags'
import NewBlog from '../components/NewBlog'
import NewQuestion from '../components/NewQuestion'
import { normailzeData } from '../components/NewQuestion/utils'
import ReviewsSection from '../components/ReviewsSection'
import SelectStageSection from '../components/SelectStageSection'
import TopicSection from '../components/TopicSection'
import { homeLdJson } from '../data/home.ldjson'
import { getLocale, localeToLanguage } from '../data/intl'
import useScreenName from '../hooks/clevertap/useScreenName'
import { useLingui } from '../hooks/locale/useLingui'

const pageName = 'Home Page'

const IndexPage: FC = () => {
  const LinguiProvider = useLingui()
  const ScreenProvider = useScreenName(pageName)
  const router = useRouter()
  const locale = getLocale(router.locale)
  const language = localeToLanguage(locale)
  const { data: seoData } = useSWR(router.isReady ? [router.asPath, 'retrieveSeoData'] : null, retrieveSeoData, { errorRetryCount: 1 })

  const { data: blogDataList } = useSWR(['ALL', 'ALL', language, 0, 'LIFESTAGE', 'retriveBlogList'], retriveBlogList, {
    errorRetryCount: 1,
    revalidateOnFocus: false,
  })
  const { data: qaDataList } = useSWR([locale, 'ALL', 'ALL', 0, 'retrieveFeed'], retrieveQAFeed, {
    errorRetryCount: 1,
    revalidateOnFocus: false,
  })
  const { data: reviews } = useSWR('retrieveReviews', retrieveReviews, { errorRetryCount: 1 })
  const { data: topics } = useSWR([language, undefined, undefined, 'retrieveTopics'], retrieveTopics, {
    errorRetryCount: 1,
    revalidateOnFocus: false,
  })

  return (
    <ScreenProvider>
      <Head>
        <script type='application/ld+json'>{JSON.stringify(homeLdJson)}</script>
      </Head>
      <MetaTags
        ogTitle='Indian Pregnancy, Parenting & Baby Products Shopping platform'
        seoData={seoData}
        title='Indian Pregnancy, Parenting & Baby Products Shopping platform'
      />
      <LinguiProvider>
        <div className='bg-w'>
          <Header />
          <AgeTypeBar />
          <BannersSection />
          <GooglePlaySection />
          {topics && topics?.length != 0 ? <TopicSection topics={topics} title={t`HEALOFY TOP CATEGOTRIES`} ageType={'ALL'} /> : null}
          {qaDataList ? <NewQuestion data={normailzeData(qaDataList)} /> : null}
          {blogDataList ? <NewBlog data={blogDataList.payload.blogs} /> : null}
          {reviews ? <ReviewsSection reviews={reviews} /> : null}
          <SelectStageSection />
          <Footer />
        </div>
      </LinguiProvider>
    </ScreenProvider>
  )
}

export default IndexPage
