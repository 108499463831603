import { ErrorBoundary } from '@sentry/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import useSWR from 'swr'

import { pushCleverEvent } from '../../api/analytics/clevertap'
import { retrieveBanners } from '../../api/feed'
import { getLocale } from '../../data/intl'
import useInViewport from '../../hooks/useInViewport'
import { Banner } from '../../interfaces'
import Slide from '../Slide'
import styles from './BannersSection.module.css'

const BannersSection = () => {
  const router = useRouter()
  const locale = getLocale(router.locale)
  const { data } = useSWR([locale, 'retrieveBanners'], retrieveBanners, { errorRetryCount: 1 })

  const banners = useMemo(() => data || ([] as Array<Banner>), [data])

  const cards = banners.map((banner, index) => (
    <div key={banner.bannerId}>
      <Link href={banner.redirectLink}>
        <a onClick={() => pushCleverEvent('banner_click', { bannerId: banner.bannerId, index })}>
          <img className={styles.banner} src={banner.imageUrl} alt={''} />
        </a>
      </Link>
    </div>
  ))

  const { isInViewport, r } = useInViewport<HTMLDivElement>()

  const onNewSlide = useCallback(
    (index: number) => {
      if (isInViewport && document.visibilityState === 'visible' && banners.length) {
        pushCleverEvent('banner_visible', { bannerId: banners[index].bannerId, index })
      }
    },
    [banners, isInViewport]
  )

  // todo - need loader
  if (!banners.length) {
    return <div className={styles.placeholder} />
  }

  return (
    <ErrorBoundary fallback={<></>}>
      <div className={styles.wrapper} ref={r}>
        <div className={styles.pink} />
        <div className={styles.slideWrapper}>
          <Slide afterChange={onNewSlide} autoplay={true} autoplaySpeed={5000} cards={cards} dots={true} slidesToShow={1} />
        </div>
        <div className={styles.pink} />
      </div>
    </ErrorBoundary>
  )
}

export default BannersSection
