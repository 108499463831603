import { FC } from 'react'

interface Props {
  size: string
}

const Arrow: FC<Props> = props => (
  <svg
    height={props.size}
    version='1.1'
    viewBox='0 0 33 33'
    width={props.size}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Combined Shape</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='010_Mobile_Homescreen' transform='translate(-239.000000, -2901.000000)' fill='#FFFFFF'>
        <g id='Group-8' transform='translate(18.000000, 2677.000000)'>
          <g id='Group-45-Copy' transform='translate(0.000000, 62.000000)'>
            <g id='Group-44' transform='translate(25.910931, 150.931174)'>
              <g id='Group-36' transform='translate(195.627530, 11.659919)'>
                <path
                  d='M16.1176834,0 C25.0192341,0 32.2353667,7.21613264 32.2353667,16.1176834 C32.2353667,25.0192341 25.0192341,32.2353667 16.1176834,32.2353667 C7.21613264,32.2353667 0,25.0192341 0,16.1176834 C0,7.21613264 7.21613264,0 16.1176834,0 Z M14.3165268,8.43184001 C13.6833014,7.90342571 12.740001,7.9364516 12.1455349,8.5309177 L12.1455349,8.5309177 L12.0464573,8.63931048 C11.518043,9.27253593 11.5510688,10.2158362 12.1455349,10.8103023 L12.1455349,10.8103023 L17.8432438,16.5090916 L12.1455349,22.2072255 L12.0464573,22.3156183 C11.518043,22.9488438 11.5510688,23.8921441 12.1455349,24.4866102 C12.7749696,25.1160449 13.7954849,25.1160449 14.4249196,24.4866102 L14.4249196,24.4866102 L21.2630735,17.6484563 L21.3621512,17.5400635 C21.8905655,16.906838 21.8575396,15.9635377 21.2630735,15.3690716 L21.2630735,15.3690716 L14.4249196,8.5309177 Z'
                  id='Combined-Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Arrow
